import {
  ILayer,
  ILayerBorder,
  ILayerColor,
  ILayerUnion,
  LayerType,
  TextAlign,
  TextValign,
} from '../layer/LayersState';
import { useMemo } from 'react';
import { isLayerColorEqual } from '../../lib/isLayerColorEqual';

export interface IAggregateAttribute<T> {
  /**
   * The aggregated value for this attribute
   */
  value: T | null;

  /**
   * Total layers scanned where this attribute was present
   */
  count: number;

  /**
   * Whether this value is mixed, i.e. different across multiple layers having this attribute
   */
  mixed: boolean;
}

/**
 * Contains aggregated information about attributes in multiple selected layers
 */
export interface IAggregatedLayerStyle {
  /**
   * Total number of selected layers
   */
  totalCount: number;

  /**
   * A dictionary of LayerType → layers selected of that type
   */
  countByType: Record<LayerType, number>;

  border: IAggregateAttribute<ILayerBorder>;
  bold: IAggregateAttribute<boolean>;
  italic: IAggregateAttribute<boolean>;
  underline: IAggregateAttribute<boolean>;
  uppercase: IAggregateAttribute<boolean>;
  fontSize: IAggregateAttribute<number>;
  fontColor: IAggregateAttribute<ILayerColor>;
  fontFamily: IAggregateAttribute<string>;
  textBackground: IAggregateAttribute<ILayerColor>;
  align: IAggregateAttribute<TextAlign>;
  valign: IAggregateAttribute<TextValign>;
  lineHeight: IAggregateAttribute<number>;
  letterSpacing: IAggregateAttribute<number>;
}

/**
 * Provides aggregated style information about all currently selected layers
 *
 * OPTIMIZE: should be a memoized selector for performance
 */
export function useLayerStyleAggregate(selectedLayers: ILayer[]) {
  return useMemo(() => createStyleAggregate(selectedLayers), [selectedLayers]);
}

export function createStyleAggregate(selectedLayers: ILayer[]): IAggregatedLayerStyle {
  const def = () => ({ count: 0, value: null, mixed: false });

  const info: IAggregatedLayerStyle = {
    totalCount: selectedLayers.length,
    countByType: {
      [LayerType.Invalid]: 0,
      [LayerType.Text]: 0,
      [LayerType.Image]: 0,
      [LayerType.Plain]: 0,
    },
    border: def(),
    bold: def(),
    italic: def(),
    underline: def(),
    uppercase: def(),
    fontSize: def(),
    fontColor: def(),
    fontFamily: def(),
    textBackground: def(),
    align: def(),
    valign: def(),
    lineHeight: def(),
    letterSpacing: def(),
  };

  if (!selectedLayers.length) {
    return info;
  }

  function determine<LayerT extends ILayer, AttrT>(
    layer: LayerT,
    infoAttrResolver: (info: IAggregatedLayerStyle) => IAggregateAttribute<AttrT>,
    layerAttrResolver: (layer: LayerT) => AttrT,
    equalFn: (a: AttrT | null, b: AttrT) => boolean = (a: AttrT | null, b: AttrT) => a === b,
  ) {
    const infoAttr = infoAttrResolver(info);
    const layerAttrValue = layerAttrResolver(layer);
    ++infoAttr.count;

    if (infoAttr.count === 1) {
      infoAttr.value = layerAttrValue;
      infoAttr.mixed = false;
      return;
    }
    // only continue evaluating if the value is not mixed yet
    if (!infoAttr.mixed && !equalFn(infoAttr.value, layerAttrValue)) {
      infoAttr.value = null;
      infoAttr.mixed = true;
    }
  }

  for (const layer of selectedLayers as ILayerUnion[]) {
    ++info.countByType[layer.type];

    if (layer.type === LayerType.Text) {
      determine(
        layer,
        (i) => i.bold,
        (l) => l.formatting.bold,
      );
      determine(
        layer,
        (i) => i.italic,
        (l) => l.formatting.italic,
      );
      determine(
        layer,
        (i) => i.underline,
        (l) => l.formatting.underline,
      );
      determine(
        layer,
        (i) => i.uppercase,
        (l) => l.formatting.uppercase,
      );
      determine(
        layer,
        (i) => i.fontSize,
        (l) => l.formatting.fontSize,
      );
      determine(
        layer,
        (i) => i.fontColor,
        (l) => l.formatting.color.foreground,
        isLayerColorEqual,
      );
      determine(
        layer,
        (i) => i.letterSpacing,
        (l) => l.formatting.letterSpacing,
      );
      determine(
        layer,
        (i) => i.align,
        (l) => l.formatting.align,
      );
      determine(
        layer,
        (i) => i.valign,
        (l) => l.formatting.valign,
      );
      determine(
        layer,
        (i) => i.lineHeight,
        (l) => l.formatting.lineHeight,
      );
      determine(
        layer,
        (i) => i.fontFamily,
        (l) => l.formatting.fontFamily,
      );
    }
    determine(
      layer,
      (i) => i.border,
      (l) => l.border,
      (a, b) => {
        return Boolean(a && b && a.size === b.size && isLayerColorEqual(a.color, b.color));
      },
    );
  }

  return info;
}
